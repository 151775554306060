import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';

const useNotificationStyles = makeStyles(() => ({
  variantSuccess: {
    backgroundColor: '#161920 !important',
    backgroundImage: 'linear-gradient(to bottom, #1A1D24, #161920) !important',
    color: '#FAFAFA !important',
    borderTop: '4px solid #0AB377',
    borderRadius: '0 0 4px 4px',
    boxShadow: '0 2px 8px rgba(10, 179, 119, 0.25)',
    '& .MuiSnackbarContent-message': {
      fontSize: '14px',
      fontWeight: 500,
      textShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
    },
    '& .MuiSnackbarContent-action': {
      color: '#0ECB81',
    },
  },
  variantError: {
    backgroundColor: '#161920 !important',
    backgroundImage: 'linear-gradient(to bottom, #1A1D24, #161920) !important',
    color: '#FAFAFA !important',
    borderTop: '4px solid #E6324B',
    borderRadius: '0 0 4px 4px',
    boxShadow: '0 2px 8px rgba(230, 50, 75, 0.25)',
    '& .MuiSnackbarContent-message': {
      fontSize: '14px',
      fontWeight: 500,
      textShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
    },
    '& .MuiSnackbarContent-action': {
      color: '#F6465D',
    },
  },
}));

export const CustomSnackbarProvider = (props) => {
  const classes = useNotificationStyles();

  return (
    <SnackbarProvider
      {...props}
      classes={{
        variantSuccess: classes.variantSuccess,
        variantError: classes.variantError,
      }}
    />
  );
};